import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link, useMatch, Outlet } from "react-router-dom";
import { Paper, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useAppContext } from "../app-layout/context";
import { ColorDivider } from "../../components/color-divider";
import { ScrollableContainer } from "../../components/scrollable-container";
import { TABS_NAME } from "../../utils/constants";
import { RoleIndicator } from "../../pages/users/components/role-indicator";

export const CompanyLayout = () => {
  const { t } = useTranslation("modules");
  const match = useMatch("/companies/:id/:tab/*");
  const userMatch = useMatch("/companies/:id/:tab/:userId");
  const tab = match?.params?.tab || TABS_NAME.module;
  const userId = userMatch?.params?.userId;

  const { isAdmin, profileByCompany } = useAppContext();
  const isShowHeaderUsers = isAdmin && tab === TABS_NAME.users;
  const isShowTabs = !userId;

  const Wrapper = isShowHeaderUsers ? Fragment : ScrollableContainer;

  return (
    <Paper
      elevation={0}
      sx={{
        flex: 1,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isShowTabs && (
        <>
          <Stack direction="row" justifyContent="space-between">
            <Tabs
              sx={{ pt: 1.25, px: 2.5 }}
              value={tab}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                sx={{ border: "none", paddingLeft: 1 }}
                value="module"
                label={
                  <Typography variant="body2">{t("tabs.module")}</Typography>
                }
                component={Link}
                to={TABS_NAME.module}
              />
              {/*<Tab
                sx={{ border: "none", paddingLeft: 1 }}
                value="projects"
                label={
                  <Typography variant="body2">{t("tabs.projects")}</Typography>
                }
                component={Link}
                to={TABS_NAME.projects}
              />*/}
              {(profileByCompany.divisionMain ||
                profileByCompany.division) && (
                  <Tab
                    sx={{ border: "none", paddingLeft: 1 }}
                    value="assignments"
                    label={
                      <Typography variant="body2">
                        {t("tabs.assignments")}
                      </Typography>
                    }
                    component={Link}
                    to={TABS_NAME.assignments}
                  />
                )}
              <Tab
                value="profile"
                sx={{ border: "none" }}
                label={
                  <Typography variant="body2">{t("tabs.profile")}</Typography>
                }
                component={Link}
                to={TABS_NAME.profile}
              />
              {isAdmin && (
                <Tab
                  value="users"
                  sx={{ border: "none" }}
                  label={
                    <Typography variant="body2">{t("tabs.users")}</Typography>
                  }
                  component={Link}
                  to={TABS_NAME.users}
                />
              )}

              <Tab
                value="counterparties"
                sx={{ border: "none" }}
                label={
                  <Typography variant="body2">
                    {t("tabs.counterparties")}
                  </Typography>
                }
                component={Link}
                to={TABS_NAME.counterparties}
              />

              {isAdmin && (
                <Tab
                  value="administration"
                  sx={{ border: "none" }}
                  label={
                    <Typography variant="body2">
                      {t("tabs.administration")}
                    </Typography>
                  }
                  component={Link}
                  to={TABS_NAME.administration}
                />
              )}
            </Tabs>
            {isShowHeaderUsers && (
              <Stack pr={2.5} pt={1.25} direction="row" alignItems="center">
                <RoleIndicator />
                <Typography ml={2} mr={3} variant="body2">
                  {t("user")}
                </Typography>
                <RoleIndicator isAdmin={true} />
                <Typography ml={2} mr={1} variant="body2">
                  {t("admin")}
                </Typography>
              </Stack>
            )}
          </Stack>
          <ColorDivider sx={{ marginRight: 2.5, marginLeft: 2.5 }} />
        </>
      )}

      <Wrapper>
        <Outlet />
      </Wrapper>
    </Paper>
  );
};
