import { createContext } from "react";
import { FormikValues } from "formik/dist/types";
import { GetUserFiltersResponse } from "../../api/company-admin";
import { CounterpartyProfileRes } from "../../api/counterparty/types";
import {Company} from "../../api/companies";

interface UserContext {
  userValues: FormikValues;
  filtersData: GetUserFiltersResponse;
  counterparties: CounterpartyProfileRes[];
  fromCounterpartyID?: number;
}

export const initContextFiltersData = {
  divisions: [],
  modules: [],
  roles: [],
  counterparties: [],
};

export const UserContext = createContext<UserContext>({
  userValues: {},
  filtersData: initContextFiltersData,
  counterparties: [],
});
